import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  data: () => ({
    seoCityList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    companyList: [],
    company_id: "",
    selectedId: 0,
    selectedRows: [],
    sortColumn: "code",
    sortDirection: "asc",
    webUrl: process.env.VUE_APP_WEB_URL,
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },
  computed: {
    selectAllList() {
      if (this.seoCityList.length > 0) {
        return (
          _.where(this.seoCityList, { isSelected: true }).length ===
          this.seoCityList.length
        );
      }
      return false;
    },
    hasSEOCityShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasSEOCityAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasSEOCityDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasSEOCityUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },

    hasSEOCityHideShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Seo City Show|Hide" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Seo City Show|Hide" })
                .length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, {
                    name: "Seo City Show|Hide",
                  }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    deleteRecord(id) {
      let _vm = this;
      let codes = [];

      if (id) {
        codes = [id];
      } else {
        let selectedId = _vm.selectedId || null;

        if (selectedId) {
          codes = [selectedId];
        } else {
          _vm.seoCityList.forEach((element) => {
            if (element.isSelected) {
              codes.push(element.code);
            }
          });
        }
      }
      this.modal7;
      _vm.axios
        .post(`seo/cities/` + _vm.company_id + `/deletes`, { codes: codes })
        .then(function (response) {
          _vm.getAll();
          _vm.selectedId = null;
          _vm.isSelected = false;
        })
        .catch(function () {
          _vm.getAll();
        });
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    updatecityStatus(city, $event) {
      let _vm = this;
      let _msg =
        city.active == true
          ? "Are you sure you want to Unpublish this Seo-city?"
          : "Are you sure you want to Publish this Seo-city?";
      let _active = city.active == true ? 1 : 0;
      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .get(`seo/cities/` + _vm.company_id + `/status/` + city.code)
            .then(function () {
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById("city-checkbox-" + city.id).checked =
                city.active;
            });
        })
        .catch(function () {
          document.getElementById("city-checkbox-" + city.id).checked =
            city.active;
        });
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/seo/cities/" + _vm.company_id + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.seoCityList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {});
    },
    CompanySlugData() {
      let _vm = this;
      _vm.getAll();
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.company_id = _vm.companyList[0].id;
          _vm.getAll();
        })
        .catch(function () {});
    },
    handleCheck(e) {
      if (e.target.code === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.seoCityList.map(
            (item) => "checkbox" + item.code
          );
          this.seoCityList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.seoCityList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.code);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.code) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getCompany();
  },
};
